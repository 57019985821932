import React, { useState } from "react";
import PlayerModal from "../player/PlayerModal";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import Awards from "./KaderPlayerAwards";

const KaderPlayer = (props) => {
  const [showPlayerModal, setShowPlayerModal] = useState(false);
  const [player, setPlayer] = useState(0);

  return (
    <>
      <tr className="small">
        <td>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowPlayerModal(true);
              setPlayer(props.player.id);
            }}
          >
            {props.player.vorname} {props.player.nachname}
          </span>
          {Object.keys(props.awards).length > 0 && (
            <Awards awards={props.awards} mobile={props.mobile} />
          )}
        </td>
        <td>{"#" + props.nummer}</td>
        <td className="text-center">
          {+props.player.games >= 4 || props.position === 1 ? (
            <CheckIcon color="success" />
          ) : (
            <CloseIcon color="error" />
          )}
        </td>
        <td>{props.player.games}</td>
        <td>
          {props.position === 1 ? props.player.gtsp : props.player.points}
        </td>
        <td>
          {props.position === 1
            ? props.player.goalsAgainst
            : props.player.goals}
        </td>
        <td>
          {props.position === 1 ? props.player.shutouts : props.player.assist}
        </td>
        <td>{props.player.strafen}</td>
      </tr>
      {player !== 0 && (
        <PlayerModal
          show={showPlayerModal}
          hide={() => setShowPlayerModal(false)}
          player={player}
          team={props.team}
        />
      )}
    </>
  );
};

export default KaderPlayer;
