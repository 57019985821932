import React from "react";
import TeamLogo from "../teams/Logo";

const PlayerFoto = ({ player, team, size = 84 }) => {
  const [showFallback, setShowFallback] = React.useState(false);
  const [photoPath, setPhotoPath] = React.useState(null);

  React.useEffect(() => {
    const loadPlayerPhoto = () => {
      try {
        // Try JPG first
        const jpgPath = require(`../../images/Fotos/Players/${player.team.team_id}/${player.id}.jpg`);
        setPhotoPath(jpgPath);
        setShowFallback(false);
      } catch {
        try {
          // If JPG fails, try PNG
          const pngPath = require(`../../images/Fotos/Players/${player.team.team_id}/${player.id}.png`);
          setPhotoPath(pngPath);
          setShowFallback(false);
        } catch {
          setPhotoPath(null);
          setShowFallback(true);
        }
      }
    };

    loadPlayerPhoto();
  }, [player]);

  if (showFallback) {
    return <TeamLogo detail team={team} />;
  }

  const containerStyle = {
    width: size,
    height: size,
    position: "relative",
    overflow: "hidden",
    borderRadius: "50%",
    backgroundColor: "#e9ecef",
  };

  const imageStyle = {
    position: "absolute",
    top: player.team.team_id === 1 ? "0" : "50%",
    left: "50%",
    transform:
      player.team.team_id === 1 ? "translateX(-50%)" : "translate(-50%, -50%)",
    width: "100%",
    ...(player.team.team_id !== 1 && { height: "100%" }),
    objectFit: "cover",
  };

  return (
    <div style={containerStyle}>
      <img
        src={photoPath}
        style={imageStyle}
        onError={() => setShowFallback(true)}
      />
    </div>
  );
};

export default PlayerFoto;
