export const SET_PLAN = "SET_PLAN";
export const ADD_TABELLE = "ADD_TABELLE";
export const SET_LOAD_HR = "SET_LOAD_HR";
export const SET_LOAD_PO = "SET_LOAD_PO";
export const SET_LOAD_HR_GOALIE = "SET_LOAD_HR_GOALIE";
export const SET_LOAD_PO_GOALIE = "SET_LOAD_PO_GOALIE";
export const SET_SPIELPLAN = "SET_SPIELPLAN";
export const SET_STATS_HR = "SET_STATS_HR";
export const SET_STATS_PO = "SET_STATS_PO";
export const SET_STATS_HR_GOALIE = "SET_STATS_HR_GOALIE";
export const SET_STATS_PO_GOALIE = "SET_STATS_PO_GOALIE";
export const SET_LOAD_TABELLE = "SET_LOAD_TABELLE";
