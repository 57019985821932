import React, { useEffect, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GET } from "../tools/fetch";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import TeamLogo from "../teams/Logo";
import PlayerModal from "../player/PlayerModal";
import { useNavigate } from "react-router-dom";

const ListGoalie = () => {
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState("gtsp");
  const [list, setList] = useState([]);
  const [backupList, setBackupList] = useState([]);
  const [direction, setDirection] = useState("asc");
  const [sortTeam, setSortTeam] = useState(0);
  const [season, setSeason] = useState(3);
  const [width, setWidth] = useState(window.innerWidth);
  const teams = useSelector((state) => state.teams.teams);
  const isMobile = width <= 768;
  const [typ, setTyp] = useState("hr");
  const [player, setPlayer] = useState(0);
  const [showPlayerModal, setShowPlayerModal] = useState(false);
  const dispatch = useDispatch();
  const loadingStateHR = useSelector((state) => state.Loadings.HRStatsGoalie);
  const statsHR = useSelector((state) => state.Stats.HRStatsGoalie);
  const statsPO = useSelector((state) => state.Stats.POStatsGoalie);
  const loadingStatePO = useSelector((state) => state.Loadings.POStatsGoalie);
  const navigate = useNavigate();

  const loadScorer = async () => {
    if (
      (typ === "hr" && loadingStateHR !== true) ||
      (typ === "po" && loadingStatePO !== true)
    ) {
      setLoading(true);
      const request = await GET(
        `/stats/goalie?sort=${sort}&order=${direction}&typ=${typ}&season=${season}`
      );
      if (request) {
        setList(request.data);
        setBackupList(request.data);
      }
      if (typ === "hr") {
        dispatch({ type: "SET_LOAD_HR_GOALIE", payload: true });
        dispatch({ type: "SET_STATS_HR_GOALIE", payload: request.data });
      } else if (typ === "po") {
        dispatch({ type: "SET_LOAD_PO_GOALIE", payload: true });
        dispatch({ type: "SET_STATS_PO_GOALIE", payload: request.data });
      }
      setLoading(false);
    } else {
      if (typ === "hr") {
        setList(statsHR);
        setBackupList(statsHR);
      } else if (typ === "po") {
        setList(statsPO);
        setBackupList(statsPO);
      }
      setLoading(false);
      setDirection("asc");
      setSort("gtsp");
      setSortTeam(0);
    }
  };

  const getTeam = (id) => {
    const team = teams.find((team) => team.id === +id);
    const name = isMobile ? team.short : team.name;
    return team ? name : "";
  };

  const getBack = (rank) => {
    if (rank === 1) {
      return {
        background:
          "linear-gradient(to right,rgba(255,215,0,0.75),transparent)",
        cursor: "pointer",
      };
    } else if (rank === 2) {
      return {
        background:
          "linear-gradient(to right,rgba(192,192,192,0.75),transparent)",
        cursor: "pointer",
      };
    } else if (rank === 3) {
      return {
        background:
          "linear-gradient(to right,rgba(150,116,68,0.75),transparent)",
        cursor: "pointer",
      };
    } else {
      return { cursor: "pointer" };
    }
  };

  const handleChangeDirection = (sortBy) => {
    if (sortBy === sort) {
      if (direction === "desc") {
        setDirection("asc");
      } else {
        setDirection("desc");
      }
    } else {
      setSort(sortBy);
      setDirection("desc");
    }
  };

  function sortData(sortBy, sortOrder, sortTeam) {
    let sortedData = [...backupList];
    if (sortTeam !== 0) {
      sortedData = sortedData.filter((player) => player.team_id === +sortTeam);
    } else {
      sortedData = [...backupList];
    }

    sortedData.sort((a, b) => {
      let sort = 0;

      if (sortBy === "gtsp") {
        if (a.gtsp !== b.gtsp) {
          sort = b.gtsp - a.gtsp;
        } else if (a.games !== b.games) {
          sort = b.games - a.games;
        } else if (a.goals_against !== b.goals_against) {
          sort = b.goals_against - a.goals_against;
        }
      } else if (sortBy === "goals_against") {
        if (a.goals_against !== b.goals_against) {
          sort = b.goals_against - a.goals_against;
        } else if (a.gtsp !== b.gtsp) {
          sort = b.gtsp - a.gtsp;
        } else if (a.games !== b.games) {
          sort = b.games - a.games;
        }
      } else if (sortBy === "shutouts") {
        if (a.shutouts !== b.shutouts) {
          sort = b.shutouts - a.shutouts;
        } else if (a.gtsp !== b.gtsp) {
          sort = b.gtsp - a.gtsp;
        } else if (a.games !== b.games) {
          sort = b.games - a.games;
        }
      } else if (sortBy === "games") {
        if (a.games !== b.games) {
          sort = b.games - a.games;
        } else if (a.gtsp !== b.gtsp) {
          sort = b.gtsp - a.gtsp;
        } else if (a.goals_against !== b.goals_against) {
          sort = b.goals_against - a.goals_against;
        } else if (a.shutouts !== b.shutouts) {
          sort = b.shutouts - a.shutouts;
        }
      }

      if (sortOrder === "desc") {
        return sort;
      } else {
        return -sort;
      }
    });
    const sortedData2 = addRank(sortedData, sortBy);
    return sortedData2;
  }

  const addRank = (points, sort) => {
    let prev = null;
    let rank = 1;
    let sort1, sort2, sort3, sort4;
    if (sort === "gtsp") {
      sort1 = "gtsp";
      sort2 = "games";
      sort3 = "goals_against";
      sort4 = "shutouts";
    } else if (sort === "goals_against") {
      sort1 = "goals_against";
      sort2 = "games";
      sort3 = "gtsp";
      sort4 = "shutouts";
    } else if (sort === "shutouts") {
      sort1 = "shutouts";
      sort2 = "games";
      sort3 = "gtsp";
      sort4 = "goals_against";
    } else if (sort === "games") {
      sort1 = "games";
      sort2 = "gtsp";
      sort3 = "goals_against";
      sort4 = "shutouts";
    }
    let jumps = 0;
    return points.map((point) => {
      if (
        prev !== null &&
        prev[sort1] === point[sort1] &&
        prev[sort2] === point[sort2] &&
        prev[sort3] === point[sort3] &&
        prev[sort4] === point[sort4]
      ) {
        point.rank = prev.rank;
        jumps += 1;
      } else {
        point.rank = rank + jumps;
        rank++;
        jumps = 0;
      }
      prev = point;
      return point;
    });
  };

  const handleTeamChange = (id) => {
    if (id === sortTeam) {
      setSortTeam(0);
    } else {
      setSortTeam(+id.target.id);
    }
    if (id.target.checked === false) {
      setSortTeam(0);
    }
  };

  const handleSeasonChange = (id) => {
    setSeason(+id.target.value);
    dispatch({ type: "SET_LOAD_HR_GOALIE", payload: false });
    dispatch({ type: "SET_LOAD_PO_GOALIE", payload: false });
  };

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    setList(sortData(sort, direction, sortTeam));
  }, [sort, direction, sortTeam]);

  useEffect(() => {
    loadScorer();
  }, [typ, season]);

  useEffect(() => {
    setList(sortData("gtsp", "asc", 0));
  }, [backupList]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-evenly mt-2">
        <Button
          className="text-white"
          variant="outline-secondary"
          onClick={() => navigate("/scorer")}
        >
          Feldspieler
        </Button>
        <Button
          className="text-white"
          variant="secondary"
          onClick={() => navigate("/scorerGoalie")}
        >
          Torhüter
        </Button>
      </div>
      <div className="d-block text-center">
        {teams.map((team, id) => {
          return (
            <FormControlLabel
              key={id}
              label={
                <>
                  <TeamLogo team={team.id} scorer={true} />{" "}
                  {isMobile ? team.short : team.name}
                </>
              }
              control={
                <Checkbox
                  sx={{ color: "white" }}
                  color="default"
                  checked={+sortTeam === +team.id}
                  id={`${team.id}`}
                  onChange={(e) => handleTeamChange(e)}
                  key={id}
                />
              }
            />
          );
        })}
      </div>
      <div className="d-flex justify-content-evenly">
        <Button
          className="text-white"
          variant={typ === "hr" ? "secondary" : "outline-secondary"}
          onClick={() => setTyp("hr")}
        >
          Hauptrunde
        </Button>
        <Button
          className="text-white"
          variant={typ === "po" ? "secondary" : "outline-secondary"}
          onClick={() => setTyp("po")}
        >
          Playoffs
        </Button>
        <FormControl size="small">
          <InputLabel sx={{ color: "white" }}>Saison</InputLabel>
          <Select
            MenuProps={{
              PaperProps: {
                sx: {
                  backgroundColor: "rgba(0, 0, 0, 0.8)", // Adjust as needed for the dropdown background
                },
              },
            }}
            sx={{
              color: "white",
              "& .MuiSvgIcon-root": {
                // This targets the dropdown arrow
                color: "white",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                // This targets the outline
                borderColor: "white",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
            }}
            label="Saison"
            value={season}
            onChange={(e) => handleSeasonChange(e)}
          >
            <MenuItem value={0} sx={{ color: "white" }}>
              Alltime
            </MenuItem>
            <MenuItem value={3} sx={{ color: "white" }}>
              24/25
            </MenuItem>
            <MenuItem value={2} sx={{ color: "white" }}>
              23/24
            </MenuItem>
            <MenuItem value={1} sx={{ color: "white" }}>
              22/23
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      <Container className="p-0">
        <Table responsive size="sm" className="text-white g-0">
          <thead className="g-0">
            <tr className="g-0">
              <th>#</th>
              <th>Name</th>
              <th>Team</th>
              <th
                style={{ cursor: "pointer", width: "100px" }}
                className={sort === "games" ? "text-danger" : undefined}
                onClick={() => {
                  handleChangeDirection("games");
                }}
              >
                Sp.{" "}
                {sort === "games" && direction === "desc" && (
                  <KeyboardDoubleArrowDownIcon fontSize="sm" />
                )}
                {sort === "games" && direction === "asc" && (
                  <KeyboardDoubleArrowUpIcon fontSize="sm" />
                )}
              </th>
              <th
                style={{ cursor: "pointer", width: "100px" }}
                className={sort === "gtsp" ? "text-danger" : undefined}
                onClick={() => {
                  handleChangeDirection("gtsp");
                }}
              >
                GT/Sp.{" "}
                {sort === "gtsp" && direction === "desc" && (
                  <KeyboardDoubleArrowDownIcon fontSize="sm" />
                )}
                {sort === "gtsp" && direction === "asc" && (
                  <KeyboardDoubleArrowUpIcon fontSize="sm" />
                )}
              </th>
              <th
                style={{ cursor: "pointer", width: "100px" }}
                className={sort === "goals_against" ? "text-danger" : undefined}
                onClick={() => {
                  handleChangeDirection("goals_against");
                }}
              >
                GT{" "}
                {sort === "goals_against" && direction === "desc" && (
                  <KeyboardDoubleArrowDownIcon fontSize="sm" />
                )}
                {sort === "goals_against" && direction === "asc" && (
                  <KeyboardDoubleArrowUpIcon fontSize="sm" />
                )}
              </th>
              <th
                style={{ cursor: "pointer", width: "100px" }}
                className={sort === "shutouts" ? "text-danger" : undefined}
                onClick={() => {
                  handleChangeDirection("shutouts");
                }}
              >
                SO{" "}
                {sort === "shutouts" && direction === "desc" && (
                  <KeyboardDoubleArrowDownIcon fontSize="sm" />
                )}
                {sort === "shutouts" && direction === "asc" && (
                  <KeyboardDoubleArrowUpIcon fontSize="sm" />
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {list.length !== 0 &&
              list.map((player, id) => {
                return (
                  <tr
                    key={id}
                    style={getBack(player.rank)}
                    onClick={() => {
                      setShowPlayerModal(true);
                      setPlayer(player.player_id);
                    }}
                  >
                    <td>{player.rank}</td>
                    <td>
                      {player.player_name}, {player.player_firstname}
                    </td>
                    <td>{getTeam(player.team_id)}</td>
                    <td>{player.games}</td>
                    <td>{player.gtsp}</td>
                    <td>{player.goals_against}</td>
                    <td>{player.shutouts}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Container>
      {player !== 0 && (
        <PlayerModal
          show={showPlayerModal}
          hide={() => setShowPlayerModal(false)}
          player={player}
        />
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default ListGoalie;
