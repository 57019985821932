import {
  SET_LOAD_HR,
  SET_LOAD_PO,
  SET_LOAD_HR_GOALIE,
  SET_LOAD_PO_GOALIE,
  SET_SPIELPLAN,
  SET_LOAD_TABELLE,
} from "../constants/actionTypes";

const initialState = {
  HRStats: false,
  POStats: false,
  HRStatsGoalie: false,
  POStatsGoalie: false,
  Spielplan: false,
  Tabelle: false,
};

const Loadings = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOAD_HR:
      return { ...state, HRStats: action.payload };
    case SET_LOAD_PO:
      return { ...state, POStats: action.payload };
    case SET_LOAD_HR_GOALIE:
      return { ...state, HRStatsGoalie: action.payload };
    case SET_LOAD_PO_GOALIE:
      return { ...state, POStatsGoalie: action.payload };
    case SET_SPIELPLAN:
      return { ...state, Spielplan: action.payload };
    case SET_LOAD_TABELLE:
      return { ...state, Tabelle: action.payload };
    default:
      return state;
  }
};

export default Loadings;
