import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TeamLogo from "../teams/Logo";
import { GET } from "../tools/fetch";

const Game = (props) => {
  const { id } = useParams();
  const [width, setWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState();
  const [game, setGame] = useState([]);
  const [goals, setGoals] = useState([]);
  const [home, setHome] = useState([]);
  const [away, setAway] = useState([]);
  const [strafen, setStrafen] = useState([]);

  const teams = useSelector((state) => state.teams.teams);
  const stadien = useSelector((state) => state.Stadien.stadien);

  const loadGoals = async () => {
    setLoading(true);
    const request = await GET(`/spiel/getgoals?game=${id}`);
    if (request) {
      setGoals(request.tore);
      setGame(request.game);
      loadKader(request.game.home_team.id, "home");
      loadKader(request.game.away_team.id, "away");
      loadStrafen();
      setLoading(false);
    }
  };

  const loadStrafen = async () => {
    setLoading(true);
    const request = await GET(`/spiel/getstrafen?game=${id}`);
    if (request) {
      setStrafen(request.strafen);
    }
  };

  const loadKader = async (team, side) => {
    const request = await GET(`/team/kaderbericht?game=${id}&team=${team}`);
    if (request) {
      if (side === "home") {
        setHome(request.kader);
      } else {
        setAway(request.kader);
      }
    }
  };

  const getGoal = (id) => {
    switch (id) {
      case 1:
        return "Tor";
      case 2:
        return "Überzahl";
      case 3:
        return "Unterzahl";
      case 4:
        return "Empty Net";
      default:
        break;
    }
  };

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    loadGoals();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let score_home = 0;
  let score_away = 0;

  const isMobile = width <= 768;

  return (
    <div>
      {loading === false && (
        <>
          <Row className="mt-2" style={{ fontSize: "calc(15px + 0.55vw)" }}>
            <Col xs="4" md="2" className="text-center align-self-center">
              <img
                alt="Team-Logo"
                width={"60px"}
                height={"60px"}
                src={require(`../../images/Logos/${game.home_team.id}.png`)}
              />
            </Col>
            <Col xs="8" md="3" className="text-center align-self-center">
              {game.home_team.name}
            </Col>
            <Col
              xs="12"
              md="2"
              className="mt-2 mb-2 text-center align-self-center"
            >
              {game.score_home} : {game.score_away}
            </Col>
            <Col xs="8" md="3" className="text-center align-self-center">
              {game.away_team.name}
            </Col>
            <Col xs="4" md="2" className="text-center align-self-center">
              <img
                alt="Team-Logo"
                width={"60px"}
                height={"60px"}
                src={require(`../../images/Logos/${game.away_team.id}.png`)}
              />
            </Col>
          </Row>
          {/*TORE*/}
          <div style={{ maxWidth: "1000px", margin: "0 auto" }}>
            <Row>
              <Col
                className="rounded p-2 mt-3 mb-2"
                style={{ background: "rgba(255,255,255,0.3)" }}
              >
                Tore
              </Col>
            </Row>
            {!isMobile &&
              goals.map((goal, id) => {
                if (goal.team_id === game.home_team.id) {
                  score_home += 1;
                } else {
                  score_away += 1;
                }
                return (
                  <Row key={id} style={{ minHeight: "50px" }}>
                    {/*HEIM*/}
                    <Col className="d-flex justify-content-end">
                      {goal.team_id === game.home_team.id && (
                        <>
                          <div className="me-5 align-self-center">
                            #{goal.goal.nummer} {goal.goal.vorname}{" "}
                            {goal.goal.nachname}
                            {goal.assist !== 0 && (
                              <div className="d-flex align-self-start">
                                <small>
                                  #{goal.assist.nummer} {goal.assist.vorname}{" "}
                                  {goal.assist.nachname}
                                </small>
                              </div>
                            )}
                          </div>
                          <div
                            className="d-flex align-self-center"
                            style={{ minWidth: "75px" }}
                          >
                            {getGoal(goal.art)}
                          </div>
                        </>
                      )}
                    </Col>
                    <Col
                      xs="1"
                      className="text-center align-self-center"
                      style={{ fontSize: "18px" }}
                    >
                      {score_home}:{score_away}
                    </Col>
                    {/*AUSWÄRTS*/}
                    <Col className="d-flex">
                      {goal.team_id === game.away_team.id && (
                        <>
                          <div
                            className="d-flex align-self-center"
                            style={{ minWidth: "75px" }}
                          >
                            {getGoal(goal.art)}
                          </div>
                          <div className="ms-5 align-self-center">
                            #{goal.goal.nummer} {goal.goal.vorname}{" "}
                            {goal.goal.nachname}
                            {goal.assist !== 0 && (
                              <div className="d-flex align-self-start">
                                <small>
                                  #{goal.assist.nummer} {goal.assist.vorname}{" "}
                                  {goal.assist.nachname}
                                </small>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>
                );
              })}
            {isMobile &&
              goals.map((goal, id) => {
                if (goal.team_id === game.home_team.id) {
                  score_home += 1;
                } else {
                  score_away += 1;
                }
                return (
                  <Row key={id} style={{ minHeight: "45px" }} className="mb-2">
                    <Col
                      xs="auto"
                      className="pe-1 ps-1 d-flex align-self-center"
                    >
                      {score_home}:{score_away}
                    </Col>
                    <Col xs="2" className="p-0 d-flex align-self-center">
                      {getGoal(goal.art)}
                    </Col>
                    <Col xs="auto" className="d-flex align-self-center">
                      <img
                        alt="Team-Logo"
                        width={"40px"}
                        height={"40px"}
                        src={require(`../../images/Logos/${goal.team_id}.png`)}
                      />
                    </Col>
                    <Col xs="auto" className="p-0 align-items-start">
                      <div>
                        #{goal.goal.nummer} {goal.goal.vorname}{" "}
                        {goal.goal.nachname}
                      </div>
                      {goal.assist !== 0 && (
                        <div className="d-flex align-self-start">
                          <small>
                            #{goal.assist.nummer} {goal.assist.vorname}{" "}
                            {goal.assist.nachname}
                          </small>
                        </div>
                      )}
                    </Col>
                  </Row>
                );
              })}
          </div>
          {/*Strafen*/}
          <div style={{ maxWidth: "1000px", margin: "0 auto" }}>
            <Row>
              <Col
                className="rounded p-2 mt-3 mb-2"
                style={{ background: "rgba(255,255,255,0.3)" }}
              >
                Strafen
              </Col>
            </Row>
            <Row>
              <Col>
                {!isMobile &&
                  strafen.map((strafe, id) => {
                    if (strafe.team_id === game.home_team.id) {
                      return (
                        <Row key={id} style={{ minHeight: "50px" }}>
                          {/*HEIM*/}
                          <Col className="d-flex justify-content-end">
                            <div className="me-5 align-self-center">
                              #{strafe.player.nummer} {strafe.player.vorname}{" "}
                              {strafe.player.nachname}
                              <div className="d-flex align-self-start">
                                <small>{strafe.art}</small>
                              </div>
                            </div>
                            <div
                              className="d-flex align-self-center"
                              style={{ minWidth: "75px" }}
                            >
                              {strafe.time} Min.
                            </div>
                          </Col>
                        </Row>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Col>
              <Col
                xs="1"
                className="text-center align-self-center"
                style={{ fontSize: "18px" }}
              ></Col>
              {/*AUSWÄRTS*/}
              <Col>
                {!isMobile &&
                  strafen.map((strafe, id) => {
                    if (strafe.team_id === game.away_team.id) {
                      return (
                        <Row key={id} style={{ minHeight: "50px" }}>
                          {/*HEIM*/}
                          <Col className="d-flex">
                            <div
                              className="d-flex align-self-center"
                              style={{ minWidth: "75px" }}
                            >
                              {strafe.time} Min.
                            </div>
                            <div className="ms-5 align-self-center">
                              #{strafe.player.nummer} {strafe.player.vorname}{" "}
                              {strafe.player.nachname}
                              <div className="d-flex align-self-start">
                                <small>{strafe.art}</small>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Col>
            </Row>
            {isMobile &&
              strafen.map((strafe, id) => {
                return (
                  <Row key={id} style={{ minHeight: "45px" }} className="mb-2">
                    <Col
                      xs="auto"
                      className="pe-1 ps-1 d-flex align-self-center"
                    ></Col>
                    <Col xs="2" className="p-0 d-flex align-self-center">
                      {strafe.time} Min.
                    </Col>
                    <Col xs="auto" className="d-flex align-self-center">
                      <img
                        alt="Team-Logo"
                        width={"40px"}
                        height={"40px"}
                        src={require(`../../images/Logos/${strafe.team_id}.png`)}
                      />
                    </Col>
                    <Col xs="auto" className="p-0 align-items-start">
                      <div>
                        #{strafe.player.nummer} {strafe.player.vorname}{" "}
                        {strafe.player.nachname}
                      </div>
                      <div className="d-flex align-self-start">
                        <small>{strafe.art}</small>
                      </div>
                    </Col>
                  </Row>
                );
              })}
          </div>
          {/*KADER*/}
          <div style={{ maxWidth: "1000px", margin: "0 auto" }}>
            <Row>
              <Col
                className="rounded p-2 mt-3 mb-2"
                style={{ background: "rgba(255,255,255,0.3)" }}
              >
                Mannschaften
              </Col>
            </Row>
            <Row>
              <Col>
                {isMobile && (
                  <Row>
                    <Col xs="2">
                      <img
                        alt="Team-Logo"
                        width={"40px"}
                        height={"40px"}
                        src={require(`../../images/Logos/${game.home_team.id}.png`)}
                      />
                    </Col>
                    <Col className="h5">{game.home_team.name}</Col>
                  </Row>
                )}
                <Row>
                  <Col style={{ minWidth: "200px" }}>
                    <b>Name</b>
                  </Col>
                  <Col style={{ maxWidth: "50px" }}>
                    <b>P</b>
                  </Col>
                  <Col style={{ maxWidth: "50px" }}>
                    <b>T</b>
                  </Col>
                  <Col style={{ maxWidth: "50px" }}>
                    <b>A</b>
                  </Col>
                  <Col style={{ maxWidth: "50px" }}>
                    <b>S</b>
                  </Col>
                </Row>
                {home.map((player, id) => {
                  if (player.team_id === game.home_team.id && player.anwesend) {
                    return (
                      <Row key={id}>
                        <Col style={{ minWidth: "200px" }}>
                          #{player.nummer} {player.playerDetails.nachname},{" "}
                          {player.playerDetails.vorname}
                        </Col>
                        <Col style={{ maxWidth: "50px" }}>
                          {player.goals + player.assist}
                        </Col>
                        <Col style={{ maxWidth: "50px" }}>{player.goals}</Col>
                        <Col style={{ maxWidth: "50px" }}>{player.assist}</Col>
                        <Col style={{ maxWidth: "50px" }}>{player.strafen}</Col>
                      </Row>
                    );
                  }
                })}
              </Col>
              <Col>
                {isMobile && (
                  <Row className="mt-2">
                    <Col xs="2">
                      <img
                        alt="Team-Logo"
                        width={"40px"}
                        height={"40px"}
                        src={require(`../../images/Logos/${game.away_team.id}.png`)}
                      />
                    </Col>
                    <Col className="h5">{game.away_team.name}</Col>
                  </Row>
                )}
                <Row>
                  <Col style={{ minWidth: "200px" }}>
                    <b>Name</b>
                  </Col>
                  <Col style={{ maxWidth: "50px" }}>
                    <b>P</b>
                  </Col>
                  <Col style={{ maxWidth: "50px" }}>
                    <b>T</b>
                  </Col>
                  <Col style={{ maxWidth: "50px" }}>
                    <b>A</b>
                  </Col>
                  <Col style={{ maxWidth: "50px" }}>
                    <b>S</b>
                  </Col>
                </Row>
                {away.map((player, id) => {
                  if (player.team_id === game.away_team.id && player.anwesend) {
                    return (
                      <Row key={id}>
                        <Col style={{ minWidth: "200px" }}>
                          #{player.nummer} {player.playerDetails.nachname},{" "}
                          {player.playerDetails.vorname}
                        </Col>
                        <Col style={{ maxWidth: "50px" }}>
                          {player.goals + player.assist}
                        </Col>
                        <Col style={{ maxWidth: "50px" }}>{player.goals}</Col>
                        <Col style={{ maxWidth: "50px" }}>{player.assist}</Col>
                        <Col style={{ maxWidth: "50px" }}>{player.strafen}</Col>
                      </Row>
                    );
                  }
                })}
              </Col>
            </Row>
          </div>
        </>
      )}
    </div>
  );
};

export default Game;
