import {
  SET_STATS_HR,
  SET_STATS_PO,
  SET_STATS_HR_GOALIE,
  SET_STATS_PO_GOALIE,
} from "../constants/actionTypes";

const initialState = {
  HRStats: [],
  POStats: [],
  HRStatsGoalie: [],
  POStatsGoalie: [],
};

const Stats = (state = initialState, action) => {
  switch (action.type) {
    case SET_STATS_HR:
      return { ...state, HRStats: action.payload };
    case SET_STATS_PO:
      return { ...state, POStats: action.payload };
    case SET_STATS_HR_GOALIE:
      return { ...state, HRStatsGoalie: action.payload };
    case SET_STATS_PO_GOALIE:
      return { ...state, POStatsGoalie: action.payload };
    default:
      return state;
  }
};

export default Stats;
