import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import TeamLogo from "../teams/Logo";
import Team from "../teams/Team";
import { GET } from "../tools/fetch";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Backdrop,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Button } from "react-bootstrap";

const SpielplanDetail = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [games, setGames] = useState();
  const [loading, setLoading] = useState();
  const [season, setSeason] = useState(3);
  const [typ, setTyp] = useState("hr");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const SpielplanIsLoaded = useSelector((state) => state.Loadings.Spielplan);
  const GamesStore = useSelector((state) => state.SpielPlan.games);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const load = async () => {
    if (SpielplanIsLoaded === false || typ == "hr" || typ == "po") {
      try {
        setLoading(true);
        const games = await GET(`/spiel/all?season=${season}&typ=${typ}`);
        const load = await GET("/spiel/tabelle");
        setGames(games.games);
        dispatch({ type: "ADD_TABELLE", payload: load.tabelle });
        dispatch({ type: "SET_PLAN", payload: games.games });
        if (season === 3 && typ == "hr") {
          dispatch({ type: "SET_SPIELPLAN", payload: true });
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    } else {
      setGames(GamesStore);
      setLoading(false);
    }
  };

  const translate_po = (typ) => {
    switch (typ) {
      case "VF1":
        return "Viertelfinale 1";
      case "VF2":
        return "Viertelfinale 2";
      case "HF1":
        return "Halbfinale 1";
      case "HF2":
        return "Halbfinale 2";
      case "LC":
        return "Quali";
      case "P3":
        return "Spiel um Platz 3";
      case "P1":
        return "Finale";
        break;

      default:
        break;
    }
  };

  const handleSeasonChange = (id) => {
    setSeason(+id.target.value);
    dispatch({ type: "SET_SPIELPLAN", payload: false });
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    load();
  }, [season, typ]);

  const isMobile = width <= 768;
  const now = new Date();
  return (
    <Container className="p-0 mt-2">
      <div className="d-flex justify-content-evenly">
        <Button
          className="text-white"
          variant={typ === "hr" ? "secondary" : "outline-secondary"}
          onClick={() => setTyp("hr")}
        >
          Hauptrunde
        </Button>
        <Button
          className="text-white"
          variant={typ === "po" ? "secondary" : "outline-secondary"}
          onClick={() => setTyp("po")}
        >
          Playoffs
        </Button>
        <FormControl size="small">
          <InputLabel sx={{ color: "white" }}>Saison</InputLabel>
          <Select
            MenuProps={{
              PaperProps: {
                sx: {
                  backgroundColor: "rgba(0, 0, 0, 0.8)", // Adjust as needed for the dropdown background
                },
              },
            }}
            sx={{
              color: "white",
              "& .MuiSvgIcon-root": {
                // This targets the dropdown arrow
                color: "white",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                // This targets the outline
                borderColor: "white",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
            }}
            label="Saison"
            value={season}
            onChange={(e) => handleSeasonChange(e)}
          >
            <MenuItem value={3} sx={{ color: "white" }}>
              24/25
            </MenuItem>
            <MenuItem value={2} sx={{ color: "white" }}>
              23/24
            </MenuItem>
            <MenuItem value={1} sx={{ color: "white" }}>
              22/23
            </MenuItem>
            <MenuItem value={4} sx={{ color: "white" }}>
              18/19
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      {loading === false &&
        Object.values(games).map((game) => {
          const time = new Date(game.time);
          const date = new Date(game.date);
          if (
            (game.playoff !== 1 && typ === "hr") ||
            (game.playoff === 1 && typ === "po")
          )
            return (
              <Row
                key={game.id}
                className="pb-1 mb-1 border-bottom border-secondary text-center align-items-center"
              >
                <Col className="g-0 gx-xl-4" xs="2">
                  <TeamLogo team={game.home_team.id} />
                </Col>
                <Col className="g-0">
                  <Team team={game.home_team} />
                </Col>
                <Col
                  className="g-0 gx-xl-4"
                  md={{ span: "2", order: "1" }}
                  xs={
                    game.bericht !== 1
                      ? { span: "12", order: "5" }
                      : { span: "2" }
                  }
                >
                  {game.bericht === 1 && (
                    <div
                      onClick={() =>
                        season !== 1 && navigate(`/game/${game.id}`)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <b>
                        <u>
                          {game.score_home}
                          {" : "}
                          {game.score_away}
                        </u>
                      </b>
                    </div>
                  )}
                  {!isMobile && (
                    <span style={{ fontSize: "calc(11px + 0.50625vw)" }}>
                      {!isMobile && game.bericht !== 1 && <div>vs.</div>}
                      <div className="small">
                        {now.toDateString() === date.toDateString()
                          ? "Heute"
                          : date.toLocaleDateString("de-DE", {
                              dateStyle: "short",
                            })}
                        ,{" "}
                        {time.toLocaleTimeString("de-DE", {
                          timeZone: "UTC",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}{" "}
                        Uhr
                      </div>
                      {game.playoff === 1 && (
                        <div className="small">{translate_po(game.po_typ)}</div>
                      )}
                    </span>
                  )}
                </Col>
                {isMobile && (
                  <Col
                    style={{ fontSize: "calc(11px + 0.50625vw)" }}
                    className="g-0 gx-xl-4"
                    md={{ span: "2", order: "1" }}
                    xs={{ span: "12", order: "5" }}
                  >
                    <div className="small">
                      {now.toDateString() === date.toDateString()
                        ? "Heute"
                        : date.toLocaleDateString("de-DE", {
                            dateStyle: "short",
                          })}
                      ,{" "}
                      {time.toLocaleTimeString("de-DE", {
                        timeZone: "UTC",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}{" "}
                      Uhr
                    </div>
                    {game.playoff === 1 && (
                      <div className="small">{translate_po(game.po_typ)}</div>
                    )}
                  </Col>
                )}
                <Col className="g-0" md={{ order: "2" }}>
                  <Team team={game.away_team} />
                </Col>
                <Col className="g-0 gx-xl-4" md={{ order: "3" }} xs="2">
                  <TeamLogo team={game.away_team.id} />
                </Col>
              </Row>
            );
        })}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default SpielplanDetail;
